<template>
  <tr
    :class="['message', expanded ? 'expanded' : '']"
    @click="toggleExpanded"
  >
    <td>
      {{ dateFormat(message.created_at, "ddd, d mmm") }}
    </td>

    <td>
      {{ message.backoff_until ? dateFormat(message.backoff_until, "ddd, d mmm HH:MM") : "-" }}
    </td>

    <td>
      {{ message.payload.order_reference || "N/A" }}
    </td>

    <td>
      {{ message.payload.consignee_gln || "N/A" }}
    </td>

    <td>
      {{ resourceTranslation }}
    </td>

    <td class="text-end px-3">
      <i
        v-if="expanded"
        class="bi-chevron-up"
      />
      <i
        v-else
        class="bi-chevron-down"
      />
    </td>
  </tr>
  <tr
    v-if="expanded"
    class="expanded"
  >
    <td colspan="6">
      <div class="d-flex flex-row w-100 my-2 p-2">
        <div class="d-flex flex-column gap-3 flex-grow-1">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="mb-0">
              Felmeddelande:
            </h5>
            <div>
              <v-btn
                v-if="isPharmacist"
                prepend-icon="mdi-check"
                class="bg-red mr-2"
                @click="acknowledgeMessage"
              >
                Ta bort order
              </v-btn>
              <v-btn
                v-if="isPharmacist"
                prepend-icon="mdi-reload"
                class="bg-blue"
                @click="retryMessage"
              >
                Försök läsa in meddelande igen
              </v-btn>
            </div>
          </div>
          <div>
            <span>
              {{ message.error }}
            </span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { ref } from 'vue'
import dateFormat from 'dateformat'
import type { AxiosInstance, AxiosError } from 'axios'
import type { InboxMessageType } from '@/types/salesOrdersFailedTableTypes'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'

const resourceTranslations: { [key: string]: string } = {
  ecommerce_sales_order: 'Care',
  eddie_sales_order: 'LMS',
}

export default {
  components: {},
  inject: {
    axios: {
      from: 'axios',
      default: () => ({} as AxiosInstance),
    },
  },
  props: {
    inboxMessage: {
      type: Object as () => InboxMessageType,
      required: true,
    },
    onAcknowledge: {
      type: Function,
      required: true,
    },
    isPharmacist: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const message = ref<InboxMessageType>(props.inboxMessage)

    return {
      dateFormat,
      message,
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    resourceTranslation() {
      return resourceTranslations[this.message.resource] || 'Okänd'
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    },
    acknowledgeMessage() {
      (this.axios as AxiosInstance)
        .patch(`/internal_api/sales_orders/${this.message.key}/acknowledge`, {})
        .then(() => {
          this.onAcknowledge(this.message.key)
        })
    },
    retryMessage() {
      (this.axios as AxiosInstance)
        .patch(`/internal_api/sales_orders/${this.message.key}/retry`, {})
        .then(() => {
          showSnackbar('Ordern har nu lästs in', 'success')
          this.onAcknowledge(this.message.key)
        })
        .catch((error: AxiosError) => {
          console.log(error)
          showSnackbar((error.response?.data as { message: string }).message, 'error')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.message {
  cursor: pointer;
  color: var(--tw-slate-700);
}

.expanded {
  background-color: var(--bs-secondary) !important;
}
</style>
