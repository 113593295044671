<script setup lang="ts">
import { useWarehouses } from '@/composables/useWarehouses'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'
import InputErrorDisplay from '@/components/InputErrorDisplay.vue'

const model = defineModel<number | null>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null): void
}>()
const { warehouses } = useWarehouses()
const vuelidate = useVuelidate({ model: { required } }, { model })

function selectLocation(event: any) {
  emit('update:modelValue', parseInt(event.target.value))
}
</script>

<template>
  <div>
    <select
      class="form-select"
      :class="{ 'is-invalid': vuelidate.model.$error }"
      data-test="warehouse-select"
      @change="selectLocation"
    >
      <option
        style="display: none"
        value=""
        :selected="model === null"
        disabled
      />
      <option
        v-for="warehouse in warehouses"
        :key="warehouse.id"
        :value="warehouse.id"
        :selected="warehouse.id === model"
      >
        {{ warehouse.name }}
      </option>
    </select>
    <InputErrorDisplay :errors="vuelidate.model.$errors" />
  </div>
</template>
