<template>
  <div class="grid p-4">
    <div class="row">
      <div class="col fs-16 fw-medium">
        Uppdatera kvantitet
      </div>
      <div class="col-3 text-end">
        <button
          v-close-popper
          v-tooltip="'Stäng'"
          type="button"
          class="btn-close"
          aria-label="Stäng"
          @click.prevent.stop="$emit('close')"
        />
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-6">
        <VDefinition label="Ny Kvantitet">
          <LineQuantityInput
            v-model="quantity"
            :step="salesOrderLine?.item?.minimum_quantity"
          />
        </VDefinition>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <VDefinition label="Kommentar">
          <textarea
            :value="salesOrderLine.exchange_comment"
            rows="2"
            class="form-control comment"
            data-test="comment"
            @input="comment = $event.target.value"
          />
        </VDefinition>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button
          class="btn btn-success w-100 vertical-align-baseline"
          data-test="confirm-update-quantity"
          @click.prevent="confirm()"
        >
          Bekräfta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VDefinition from '@/components/VDefinition.vue'
import LineQuantityInput from '@/components/LineQuantityInput.vue'
import { ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'

export default {
  components: {
    LineQuantityInput,
    VDefinition,
  },
  inject: ['axios'],
  props: {
    salesOrderLine: {
      type: Object,
      required: true,
    },
    salesOrderId: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'confirm'],
  setup(props) {
    return {
      comment: ref(null),
      quantity: ref(parseFloat(props.salesOrderLine.quantity).toString()),
      v$: useVuelidate(),
    }
  },
  methods: {
    async confirm() {
      this.v$.$reset()
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) return

      this.axios.put(
        '/internal_api/sales_orders/' + this.salesOrderId,
        {
          lines_attributes: [
            {
              id: this.salesOrderLine.id,
              exchange_comment: this.comment,
              quantity: this.quantity,
            },
          ],
        },
      ).then((response) => {
        const salesOrder = response.data.data
        const updatedLine = salesOrder.lines.find(line => line.id === this.salesOrderLine.id)

        this.$emit('confirm', updatedLine)
      }).catch((error) => {
        const message = error.response.data?.message?.errors?.join(', ') || 'Något gick fel. Försök igen senare.'
        showSnackbar(message, 'error')
      })
    },
  },
}
</script>

<style lang="css" scoped>
.grid {
   min-width: 20rem;
}

.comment {
  max-height: 10rem;
}

.btn-close {
    padding: 0.4em;
    background-size: 10px;
    border-radius: 50%;
    background-color: #ccc;
  }
</style>
