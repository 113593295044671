<template>
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    location="bottom right"
  >
    {{ snackbar.text }}
    <template #action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar.visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { reactive } from 'vue'
export const snackbar = reactive({
  visible: false,
  text: '',
  color: '',
  timeout: 3000,
})

export function showSnackbar(text, color = '', timeout = 3000) {
  snackbar.text = text
  snackbar.color = color
  snackbar.timeout = timeout
  snackbar.visible = true
}

export default {
  setup() {
    return {
      snackbar,
      showSnackbar,
    }
  },
}
</script>
