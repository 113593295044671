<template>
  <div
    id="btn-support"
    class="fixed-bottom"
  >
    <button
      class="btn btn-primary shadow-sm m-4"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#help-offcanvas"
    >
      <i class="bi bi-send" />
      Hjälp
    </button>

    <div
      id="help-offcanvas"
      ref="help"
      class="offcanvas offcanvas-bottom"
      tabindex="-1"
    >
      <Loading
        :active="loading"
        :is-full-page="false"
      />
      <div class="container">
        <div class="row justify-items-center">
          <div>
            <div class="offcanvas-header">
              <h5
                id="help-offcanvasLabel"
                class="offcanvas-title"
              >
                Lämna synpunkter eller supportärende
              </h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
              />
            </div>
            <div
              v-if="posted"
              class="offcanvas-body text-center align-middle"
            >
              <h1><i class="bi bi-award-fill" /></h1>
              <h5>Vi har tagit emot ditt meddelande.<br>Tack för din Feedback!</h5>
            </div>
            <div v-else>
              <div class="offcanvas-body">
                <div class="row">
                  <div class="col-4">
                    <div class="list-group list-group-checkable">
                      <input
                        id="feedback-option"
                        v-model="critical"
                        class="list-group-item-check"
                        type="radio"
                        :value="false"
                      >
                      <label
                        class="list-group-item py-3"
                        for="feedback-option"
                      >
                        Synpunkt
                        <span class="d-block small opacity-50 d-none d-lg-block">Förbättringsförslag och idéer</span>
                      </label>

                      <input
                        id="critical-option"
                        v-model="critical"
                        class="list-group-item-check"
                        type="radio"
                        :value="true"
                      >
                      <label
                        class="list-group-item py-3"
                        for="critical-option"
                      >
                        Supportärende
                        <span class="d-block small opacity-50 d-none d-lg-block">Akuta ärenden som kräver snabb respons</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row h-100">
                      <div class="input-group">
                        <div
                          id="text-area"
                          contenteditable="true"
                          placeholder="Meddelande..."
                          class="form-control"
                          @input="onInput"
                        >
                          {{ message }}
                        </div>
                        <button
                          id="submit"
                          class="btn btn-primary"
                          :disabled="disabled"
                          type="button"
                          @click="submitFeedback"
                        >
                          Skicka
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'

export default {
  components: {
    Loading,
  },
  inject: ['axios'],
  data() {
    return {
      message: '',
      critical: false,
      posted: false,
      loading: false,
    }
  },
  computed: {
    disabled() {
      return !/\S+/.test(this.message)
    },
  },
  mounted() {
    this.$refs.help.addEventListener('hidden.bs.offcanvas', () => { this.posted = false })
  },
  methods: {
    onInput(e) {
      this.message = e.target.innerText
    },
    buildSubmitBody() {
      return {
        ticket: {
          message: this.message,
          context: window.location.href,
        },
      }
    },
    submitFeedback() {
      this.loading = true

      const feedbackPath = this.critical ? '/internal_api/supportbee_tickets' : '/internal_api/feedback_messages'
      this.axios.post(feedbackPath, this.buildSubmitBody())
        .then(() => {
          this.message = ''
          this.critical = false
          this.posted = true
        }).catch(() => {
          this.posted = false
          showSnackbar('Något gick fel. Försök igen senare.', 'error')
        }).then(() => {
          this.loading = false
        })
    },
  },
}

</script>

<style scoped lang="scss">
#btn-support {
  display: none;
}

.fixed-bottom {
  width: 10rem;
}

.form-control[placeholder]:not(:focus):empty:before {
  content: attr(placeholder);
  color: #555;
}

.opacity-50 { opacity: .5; }

.list-group-checkable {
  display: grid;
  gap: .5rem;
  border: 0;
}
.list-group-checkable .list-group-item {
  cursor: pointer;
  border-radius: .5rem;
}

.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.list-group-item-check:hover + .list-group-item {
  background-color: var(--bs-light);
}

.list-group-item-check:checked + .list-group-item {
  color: #fff;
  background-color: var(--bs-primary);
}

.list-group-item-check[disabled] + .list-group-item,

.list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}
</style>
