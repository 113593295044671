<template>
  <div class="grid">
    <div class="row">
      <div class="col">
        <ShippingMethodSelect
          v-model="selectedShippingMethodId"
          :customer="customer"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <button
          class="btn btn-success w-100 vertical-align-baseline"
          data-test="confirm-update-shipping-method-button"
          @click.prevent="confirm()"
        >
          Bekräfta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ShippingMethodSelect from '@/components/ShippingMethodSelect.vue'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'

export default {
  components: {
    ShippingMethodSelect,
  },
  inject: ['axios'],
  props: {
    salesOrderId: {
      type: Number,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    initialShippingMethodId: {
      type: Number,
      required: true,
    },
  },
  emits: ['confirm', 'close'],
  setup(props) {
    return { selectedShippingMethodId: props.initialShippingMethodId }
  },
  watch: {
    initialShippingMethodId() {
      this.selectedShippingMethodId = this.initialShippingMethodId
    },
  },
  methods: {
    async confirm() {
      this.axios.put(
        '/internal_api/sales_orders/' + this.salesOrderId,
        { shipping_method_id: this.selectedShippingMethodId },
      ).then((response) => {
        this.$emit('confirm', { shipping_method: response.data.data.shipping_method })
        this.$emit('close')
      }).catch((error) => {
        const message = error.response.data?.message?.errors?.join(', ') || 'Något gick fel. Försök igen senare.'
        showSnackbar(message, 'error')
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
