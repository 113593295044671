<template>
  <div class="m-3">
    <VDefinition
      label="Ursprunglig vara"
      class="mb-4"
      data-test="original-item-info"
    >
      <div class="mx-1 mt-2">
        <SimpleItemInfo :item="salesOrderLine.original_item" />
      </div>
    </VDefinition>

    <VDefinition
      v-if="itemHasChanged"
      label="Nuvarande vara"
      class="mb-4"
      data-test="current-item-info"
    >
      <div class="mx-1 mt-2">
        <SimpleItemInfo :item="salesOrderLine.item" />
      </div>
    </VDefinition>

    <div class="item-select">
      <VDefinition
        label="Ny vara"
        required
      >
        <div class="mb-2">
          <item-search
            :ref="`item-line-search-1`"
            v-model="newItem"
          />
        </div>
      </VDefinition>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <ExchangeReason v-model="exchangeReason" />
      </div>
      <div class="col">
        <VDefinition label="Kommentar">
          <textarea
            :value="exchangeComment"
            rows="1"
            class="form-control comment"
            data-test="exchange-comment"
            @input="exchangeComment = $event.target.value"
          />
        </VDefinition>
      </div>
    </div>

    <LoadingButton
      class="btn btn-success float-end confirm-button"
      :loading="loading"
      data-test="confirm-button"
      @click="confirm"
    >
      <template #perform>
        Bekräfta
      </template>
      <template #loading>
        Uppdaterar rad...
      </template>
    </LoadingButton>

    <span
      v-if="newItem && differentAtcCode"
      class="text-danger float-end m-2"
    >
      <i class="bi bi-exclamation-triangle-fill" />
      Den valda varans ATC-kod skiljer sig ifrån den ursprungliga varan
      <i class="bi bi-exclamation-triangle-fill" />
    </span>
  </div>
</template>

<script>
import ItemSearch from '@/components/ItemSearch.vue'
import ExchangeReason from '@/components/ExchangeReason.vue'
import VDefinition from '@/components/VDefinition.vue'
import useVuelidate from '@vuelidate/core'
import LoadingButton from '@/components/LoadingButton.vue'
import SimpleItemInfo from '@/components/SimpleItemInfo.vue'
import { ref } from 'vue'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'

export default {
  components: {
    ItemSearch,
    VDefinition,
    ExchangeReason,
    LoadingButton,
    SimpleItemInfo,
  },
  inject: ['axios'],
  props: {
    salesOrderId: {
      type: Number,
      required: true,
    },
    salesOrderLine: {
      type: Object,
      required: true,
    },
  },
  emits: ['update-completed'],
  setup(props) {
    return {
      newItem: ref(null),
      exchangeReason: ref(null),
      exchangeComment: ref(props.salesOrderLine.exchange_comment),
      loading: ref(false),
      v$: useVuelidate(),
    }
  },
  computed: {
    itemHasChanged() {
      return this.salesOrderLine.original_item.id !== this.salesOrderLine.item.id
    },
    differentAtcCode() {
      return this.newItem?.atc_code !== this.salesOrderLine.original_item?.atc_code
    },
  },
  methods: {
    async confirm() {
      this.v$.$reset()
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) return
      this.loading = true

      this.axios.put(
        '/internal_api/sales_orders/' + this.salesOrderId,
        {
          lines_attributes: [
            {
              id: this.salesOrderLine.id,
              item_id: this.newItem.id,
              exchange_comment: this.exchangeComment,
              exchange_reason: this.exchangeReason,
            },
          ],
        },
      ).then((response) => {
        const salesOrder = response.data.data
        const updatedLine = salesOrder.lines.find(line => line.id === this.salesOrderLine.id)

        this.$emit('update-completed', updatedLine)
      }).catch((error) => {
        const message = error.response.data?.message?.errors?.join(', ') || 'Något gick fel. Försök igen senare.'
        showSnackbar(message, 'error')
      }).then(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .confirm-button {
    width: 11rem;
  }

  .item-select {
    min-height: 5rem;
  }
  .text-center {
    text-align: center;
  }
</style>
