import { ref, type Ref } from 'vue'
import axios from '@/config/axios.js'

const defaultOptions = {
  immediate: true,
  root: [],
}

export function useFetch<T>(url: string, config = {}, options = {}) {
  const data: Ref<T | null> = ref(null)
  const response: Ref<any> = ref(null)
  const error: Ref<any> = ref(null)
  const loading = ref(false)

  const { immediate, root } = { ...defaultOptions, ...options }

  const fetch = async (configOverride = {}) => {
    loading.value = true
    try {
      const requestResponse = await axios.request({ url, ...config, ...configOverride })
      response.value = requestResponse
      data.value = getRootData(requestResponse.data)
    }
    catch (e) {
      error.value = e
    }
    finally {
      loading.value = false
    }
  }

  const getRootData = (data: any) => {
    return root.reduce((acc, key) => {
      return acc[key]
    }, data)
  }

  if (immediate) fetch()

  return { data, response, error, loading, fetch }
}
