<template>
  <div>
    <Loading
      :active="settingsLoading"
      :is-full-page="true"
    />
    <div
      v-if="settingsFetched"
      class="d-flex flex-column min-vh-100 mb-5"
    >
      <AppNavbar />
      <main>
        <RouterView />
      </main>
      <FeedbackForm />
    </div>
    <GlobalSnackbar />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue'
import Loading from 'vue-loading-overlay'
import FeedbackForm from '@/components/FeedbackForm.vue'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import { showSnackbar } from '@/components/GlobalSnackbar.vue'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    AppNavbar,
    Loading,
    FeedbackForm,
    GlobalSnackbar,
  },
  inject: ['axios'],
  data() {
    return {
      settingsLoading: false,
      settingsFetched: false,
    }
  },
  created() {
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.settingsLoading = true
      this.axios.get('/internal_api/settings')
        .then((response) => {
          this.$store.commit('setSettings', response.data)
          this.settingsFetched = true
        }).catch(() => {
          showSnackbar('Misslyckades att ladda inställningar. Testa att ladda om sidan.', 'error')
        }).then(() => {
          this.settingsLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
main {
  margin-top: 60px;
}
</style>
